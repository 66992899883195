export var DonationsReportTransactionType;
(function (DonationsReportTransactionType) {
    DonationsReportTransactionType["DonationPayment"] = "Donation Payment";
    DonationsReportTransactionType["DonationPledge"] = "Donation Pledge";
    DonationsReportTransactionType["Allocation"] = "Allocation";
})(DonationsReportTransactionType || (DonationsReportTransactionType = {}));
export var DonationsReportDonationType;
(function (DonationsReportDonationType) {
    DonationsReportDonationType["OneTime"] = "One-Time";
    DonationsReportDonationType["Recurring"] = "Recurring";
})(DonationsReportDonationType || (DonationsReportDonationType = {}));
export var DonationsReportSourceType;
(function (DonationsReportSourceType) {
    DonationsReportSourceType["Online"] = "Online";
    DonationsReportSourceType["Offline"] = "Offline";
    DonationsReportSourceType["Matching"] = "Matching";
    DonationsReportSourceType["Facebook"] = "Facebook";
})(DonationsReportSourceType || (DonationsReportSourceType = {}));
