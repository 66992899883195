export var IntegrationStatus;
(function (IntegrationStatus) {
    IntegrationStatus["Connected"] = "Connected";
    IntegrationStatus["Disconnected"] = "Disconnected";
})(IntegrationStatus || (IntegrationStatus = {}));
export var IntegrationType;
(function (IntegrationType) {
    IntegrationType["Amply"] = "AMPLY";
    IntegrationType["DoubleTheDonation"] = "DOUBLE_THE_DONATION";
    IntegrationType["Facebook"] = "FACEBOOK";
})(IntegrationType || (IntegrationType = {}));
