import { DateTime } from 'luxon';
import { DATE_SQL_FORMAT, DATE_TABLE_FORMAT, DATE_USA_FORMAT, REGEX_SQL_FORMAT, REGEX_TABLE_FORMAT, REGEX_USA_FORMAT, } from '@vizsla/constants';
import { isFn, isProp, isStr } from '../types';
/** Parse date from `string`, `number` or `Date` to luxon's {@link DateTime}. */
export function parseDate(value, options) {
    if (value instanceof DateTime) {
        return value;
    }
    if (isProp(value, 'toISO') && isFn(value.toISO)) {
        // Allow to parse other versions of luxon by formating into ISO.
        return parseDate(value.toISO());
    }
    if (isStr(value) && REGEX_USA_FORMAT.test(value)) {
        return DateTime.fromFormat(value, DATE_USA_FORMAT, options);
    }
    if (isStr(value) && REGEX_SQL_FORMAT.test(value)) {
        return DateTime.fromFormat(value, DATE_SQL_FORMAT, options);
    }
    if (isStr(value) && REGEX_TABLE_FORMAT.test(value)) {
        return DateTime.fromFormat(value, DATE_TABLE_FORMAT, options);
    }
    if (isStr(value)) {
        return DateTime.fromISO(value, options);
    }
    if (typeof value === 'number') {
        return DateTime.fromSeconds(value, options);
    }
    if (value instanceof Date) {
        return DateTime.fromJSDate(value, options);
    }
    return DateTime.invalid('(NONE_DATETIME_FORMAT_MATCH)');
}
/** Gives the current date-time as {@link DateTime} instance from `luxon`. */
export function currentDateTime() {
    return DateTime.now();
}
