import { SystemRoles, OrganizationRoles } from '@vizsla/types';
import { getUserOrganizationRoles } from './authorization';
const mapRole = role => {
    if (role === OrganizationRoles.OrganizationAdministrator) {
        return 'ORG_ADMIN_CLIENT';
    }
    if (role === 'Checkiner' || role === OrganizationRoles.Checkiner) {
        return 'CHECKIN_CLIENT';
    }
    if (role === SystemRoles.FinancialAnalyst) {
        return 'FINANCIAL_CLIENT';
    }
    if (role === SystemRoles.CampaignAdministrator) {
        return 'CAMPAIGN_ADMIN_CLIENT';
    }
    if (role === SystemRoles.CampaignStaff) {
        return 'CAMPAIGN_STAFF_CLIENT';
    }
    return role;
};
export const getAvailableRoles = (user, availableAppsList) => {
    const systemRoles = getUserOrganizationRoles(user);
    const rolesToBeExcluded = ['VIZSLA Root User'];
    const formatSystemRoles = role => ({
        id: mapRole(role),
        roleName: role,
    });
    // Map system roles to the desired format
    const formattedSystemRoles = systemRoles.map(formatSystemRoles);
    // Check if "Organization Administrator" is present in both systemRoles and availableAppsList
    const orgAdminExistsInBoth = formattedSystemRoles.some(role => role.roleName === OrganizationRoles.OrganizationAdministrator) &&
        availableAppsList.some(app => app.roleName === OrganizationRoles.OrganizationAdministrator);
    // Perform the join operation
    const joinedRoles = [
        ...formattedSystemRoles,
        ...availableAppsList.filter(app => app.roleName !== OrganizationRoles.OrganizationAdministrator || orgAdminExistsInBoth),
    ];
    const filteredRoles = joinedRoles.filter(role => !rolesToBeExcluded.includes(role === null || role === void 0 ? void 0 : role.roleName));
    const uniqueRoleNames = new Set();
    return filteredRoles.filter(item => {
        if (uniqueRoleNames.has(item.id)) {
            return false;
        }
        uniqueRoleNames.add(item.id);
        return true;
    });
};
export const getCurrentRolePopover = (roles, currentApp) => {
    var _a;
    const selectedRole = roles.find(role => role.id === currentApp);
    if (selectedRole) {
        return selectedRole.roleName;
    }
    return (_a = roles[0]) === null || _a === void 0 ? void 0 : _a.roleName;
};
