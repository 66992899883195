/** Check the given `value` as `CartItem<ShoppingCartRegistrationData>`. */
export function isShoppingCartRegistrationItem(value) {
    return value.type === 'registration';
}
/** Check the given `value` as `CartItem<ShoppingCartDonationData>`. */
export function isShoppingCartDonationItem(value) {
    return value.type === 'registration';
}
/** Check the given `value` as `CartItem<ShoppingCartAssetData>`. */
export function isShoppingCartAssetItem(value) {
    return value.type === 'registration';
}
/** Check the given `value` as `CartItem<ShoppingCartSwagBagData>`. */
export function isShoppingCartSwagBagItem(value) {
    return value.type === 'registration';
}
