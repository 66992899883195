import { SystemRoles, Application } from '@vizsla/types';
export const getUserSystemRoles = (user) => {
    var _a;
    return ((_a = user === null || user === void 0 ? void 0 : user.roles) === null || _a === void 0 ? void 0 : _a.items.map(role => role.name)) || [];
};
export const getUserOrganizationRoles = (user, organizationIndex = 0) => {
    var _a, _b;
    const roles = (_b = (_a = user === null || user === void 0 ? void 0 : user.vizslaOrganizationStaff) === null || _a === void 0 ? void 0 : _a.items[organizationIndex]) === null || _b === void 0 ? void 0 : _b.roles;
    if (roles && (roles === null || roles === void 0 ? void 0 : roles.length) > 0) {
        return roles.map(role => (role === 'Checkiner' ? 'Check-In' : role));
    }
    return [];
};
export const getUserRoles = (user, organizationIndex = 0) => {
    return {
        system: getUserSystemRoles(user),
        organization: getUserOrganizationRoles(user, organizationIndex),
    };
};
export const getAppIdByOrganizationRole = (role) => {
    switch (role) {
        case SystemRoles.Checkiner:
            return Application.CheckInClient;
        case SystemRoles.VizslaAdministrator:
            return Application.VizslaAdminClient;
        case SystemRoles.Attendee:
            return Application.ConsumerClient;
        case SystemRoles.Donor:
            return Application.ConsumerClient;
        case SystemRoles.Fundraiser:
            return Application.ConsumerClient;
        case SystemRoles.CampaignAdministrator:
            return Application.OrganizationAdminClient;
        case SystemRoles.CampaignStaff:
            return Application.OrganizationAdminClient;
        case SystemRoles.FinancialAnalyst:
            return Application.OrganizationAdminClient;
        default:
            return Application.OrganizationAdminClient;
    }
};
export const getUserCurrentApplication = (user) => {
    return user === null || user === void 0 ? void 0 : user.currentApplication;
};
export const getIsUserHaveOrganization = (user) => {
    var _a;
    return ((_a = user === null || user === void 0 ? void 0 : user.vizslaOrganizationStaff) === null || _a === void 0 ? void 0 : _a.items.length) !== 0;
};
