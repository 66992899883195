import { lighten, createTheme, } from '@mui/material';
import '@mui/lab/themeAugmentation';
import { Shadow, Border, BorderRadius, FontSize, FontWeight, LetterSpacing, PaletteColor, TextAlign, TextTransform, Display, JustifyContent, Transition, STYLE_IMPORTANT, } from './constants';
import { composeStyles as c } from './utils';
import { customPalette } from './customPalette';
const SHARED_SETTINGS = {
    fontFamily: 'Inter, sans-serif',
    inputVariant: 'outlined',
    buttonSize: 'large',
    buttonColorPreset: 'primary',
};
export const createMuiTheme = () => {
    const palette = {
        primary: {
            main: PaletteColor.PrimaryMain,
            light: PaletteColor.PrimaryLight,
            dark: PaletteColor.PrimaryDark,
            contrastText: PaletteColor.ContrastText,
        },
        secondary: {
            main: PaletteColor.SecondaryMain,
            light: PaletteColor.SecondaryLight,
            dark: PaletteColor.SecondaryDark,
            contrastText: PaletteColor.ContrastText,
        },
        success: {
            main: PaletteColor.SuccessMain,
            light: PaletteColor.SuccessLight,
            dark: PaletteColor.SecondaryDark,
            contrastText: PaletteColor.ContrastText,
        },
        warning: {
            main: PaletteColor.WarningMain,
            light: PaletteColor.WarningLight,
            dark: PaletteColor.WarningDark,
            contrastText: PaletteColor.ContrastText,
        },
        error: {
            main: PaletteColor.ErrorMain,
            light: PaletteColor.ErrorLight,
            dark: PaletteColor.ErrorDark,
            contrastText: PaletteColor.ContrastText,
        },
        info: {
            main: PaletteColor.InfoMain,
            light: PaletteColor.InfoLight,
            dark: PaletteColor.InfoDark,
            contrastText: PaletteColor.ContrastText,
        },
    };
    const typography = {
        fontFamily: SHARED_SETTINGS.fontFamily,
        color: PaletteColor.DefaultText,
        h1: {
            fontSize: FontSize.XXXXL,
            fontWeight: FontWeight.Black,
            letterSpacing: LetterSpacing.Tight,
            color: PaletteColor.DefaultText,
        },
        h2: {
            fontSize: FontSize.XXXL,
            fontWeight: FontWeight.Black,
            letterSpacing: LetterSpacing.Tight,
            color: PaletteColor.DefaultText,
        },
        h3: {
            fontSize: FontSize.XXL,
            fontWeight: FontWeight.Black,
            letterSpacing: LetterSpacing.Tight,
            color: PaletteColor.DefaultText,
        },
        h4: {
            fontSize: FontSize.XL,
            fontWeight: FontWeight.Black,
            letterSpacing: LetterSpacing.Tight,
            color: PaletteColor.DefaultText,
        },
        h5: {
            fontSize: FontSize.L,
            fontWeight: FontWeight.Black,
            letterSpacing: LetterSpacing.Tight,
            color: PaletteColor.DefaultText,
        },
        h6: {
            fontSize: FontSize.M,
            fontWeight: FontWeight.Black,
            letterSpacing: LetterSpacing.Tight,
            color: PaletteColor.DefaultText,
        },
        display1: {
            display: Display.Block,
            fontSize: FontSize.XXXXL,
            fontFamily: SHARED_SETTINGS.fontFamily,
            fontWeight: FontWeight.Regular,
            letterSpacing: LetterSpacing.Tight,
            color: PaletteColor.DefaultText,
        },
        display2: {
            display: Display.Block,
            fontSize: FontSize.XXXL,
            fontFamily: SHARED_SETTINGS.fontFamily,
            fontWeight: FontWeight.Regular,
            letterSpacing: LetterSpacing.Tight,
            color: PaletteColor.DefaultText,
        },
        subtitle1: {
            fontSize: FontSize.M,
            fontWeight: FontWeight.Bold,
            color: PaletteColor.DefaultText,
        },
        subtitle2: {
            fontSize: FontSize.Default,
            fontWeight: FontWeight.Bold,
            color: PaletteColor.DefaultText,
        },
        body1: {
            fontSize: FontSize.M,
            fontWeight: FontWeight.Regular,
            letterSpacing: LetterSpacing.Tight,
            color: PaletteColor.DefaultText,
        },
        body2: {
            fontSize: FontSize.Default,
            fontWeight: FontWeight.Regular,
            letterSpacing: LetterSpacing.Tight,
            color: PaletteColor.DefaultText,
        },
        stats: {
            display: Display.Block,
            fontFamily: SHARED_SETTINGS.fontFamily,
            fontSize: FontSize.XXXL,
            fontWeight: FontWeight.Regular,
            letterSpacing: LetterSpacing.Tight,
            color: PaletteColor.DefaultText,
        },
        tablehead: {
            fontSize: FontSize.S,
            fontFamily: SHARED_SETTINGS.fontFamily,
            fontWeight: FontWeight.Bold,
            textTransform: TextTransform.UpperCase,
            color: PaletteColor.GrayText,
        },
        tabs: {
            fontSize: FontSize.S,
            fontFamily: SHARED_SETTINGS.fontFamily,
            fontWeight: FontWeight.Bold,
            textTransform: TextTransform.UpperCase,
            color: PaletteColor.DefaultText,
        },
        button: {
            fontSize: FontSize.Default,
            fontWeight: FontWeight.Bold,
            textAlign: TextAlign.Center,
            textTransform: TextTransform.None,
            color: PaletteColor.DefaultText,
        },
        hyperlink: {
            fontWeight: FontWeight.Regular,
            fontFamily: SHARED_SETTINGS.fontFamily,
            color: customPalette.typography.color.link,
        },
        caption: {
            fontSize: FontSize.S,
            fontWeight: FontWeight.Regular,
        },
        overline: {
            fontSize: FontSize.S,
            fontWeight: FontWeight.Regular,
            color: PaletteColor.DefaultText,
        },
    };
    const buttonStyles = {
        variants: [
            {
                props: { variant: 'hyperlink' },
                style: Object.assign(Object.assign({ border: 'none', borderRadius: 0, backgroundColor: 'transparent', padding: 0 }, typography.hyperlink), { '&:hover': {
                        textDecoration: 'underline',
                        backgroundColor: 'transparent',
                    }, '&:disabled': {
                        color: customPalette.button.disabled.colorLight,
                    } }),
            },
        ],
        defaultProps: {
            variant: 'contained',
            color: SHARED_SETTINGS.buttonColorPreset,
            size: SHARED_SETTINGS.buttonSize,
        },
        styleOverrides: {
            root: ({ theme }) => ({
                minWidth: 75,
                margin: theme.spacing(0, 1),
                fontSize: c(FontSize.Default, STYLE_IMPORTANT),
                boxShadow: c('none', STYLE_IMPORTANT),
                borderRadius: 8,
                '& + &': {
                    marginLeft: 0,
                },
            }),
            text: ({ theme }) => ({
                '&:disabled': {
                    backgroundColor: theme.customPalette.button.disabled.backgroundLight,
                    color: theme.customPalette.button.disabled.colorLight,
                },
            }),
            sizeLarge: ({ theme }) => ({
                minHeight: 40,
                padding: theme.spacing(0, 3),
            }),
            contained: ({ theme }) => ({
                '&:disabled': {
                    backgroundColor: lighten(theme.palette.primary.main, 0.7),
                    color: theme.customPalette.button.primary.color.default,
                },
            }),
            containedPrimary: ({ theme }) => ({
                backgroundColor: theme.palette.primary.main,
                color: theme.customPalette.button.primary.color.default,
                '&:disabled': {
                    backgroundColor: lighten(theme.palette.primary.main, 0.7),
                    color: theme.customPalette.button.primary.color.default,
                },
            }),
            containedSecondary: ({ theme }) => ({
                backgroundColor: theme.palette.secondary.main,
                color: theme.customPalette.button.secondary.color.default,
                '&:disabled': {
                    backgroundColor: lighten(theme.palette.secondary.main, 0.7),
                    color: theme.customPalette.button.secondary.color.default,
                },
            }),
            containedError: {
                backgroundColor: customPalette.button.error.background.default,
                color: customPalette.button.error.color.default,
                '&:hover': {
                    backgroundColor: customPalette.button.error.background.hover,
                },
                '&:disabled': {
                    backgroundColor: customPalette.button.error.background.disabled,
                },
            },
            containedInfo: {
                backgroundColor: customPalette.button.info.background.default,
                color: customPalette.button.info.color.default,
                '&:hover': {
                    backgroundColor: customPalette.button.info.background.hover,
                },
                '&:disabled': {
                    backgroundColor: customPalette.button.info.background.disabled,
                },
            },
            textPrimary: {
                backgroundColor: customPalette.button.primary.background.light,
                color: customPalette.button.primary.color.light,
            },
            textSecondary: {
                backgroundColor: customPalette.button.secondary.background.light,
                color: customPalette.button.secondary.color.light,
            },
            textError: {
                backgroundColor: customPalette.button.error.background.light,
                color: customPalette.button.error.color.light,
            },
            textInfo: {
                backgroundColor: customPalette.button.info.background.light,
                color: customPalette.button.info.color.light,
                '&:hover': {
                    backgroundColor: customPalette.button.info.background.hoverLight,
                },
            },
        },
    };
    const components = {
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'stats' },
                    style: Object.assign({}, typography.stats),
                },
                {
                    props: { variant: 'display1' },
                    style: Object.assign({}, typography.display1),
                },
                {
                    props: { variant: 'display2' },
                    style: Object.assign({}, typography.display2),
                },
                {
                    props: { variant: 'tabs' },
                    style: Object.assign({}, typography.tabs),
                },
                {
                    props: { variant: 'hyperlink' },
                    style: Object.assign({}, typography.hyperlink),
                },
                {
                    props: { variant: 'tablehead' },
                    style: Object.assign({}, typography.tablehead),
                },
            ],
        },
        MuiBreadcrumbs: {
            defaultProps: {
                separator: '>',
            },
            styleOverrides: {
                root: {
                    marginLeft: '16px',
                },
                li: {
                    textDecoration: 'none',
                    color: customPalette.typography.color.default,
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
            styleOverrides: {
                root: Object.assign({}, typography.hyperlink),
            },
        },
        MuiPaper: {
            variants: [
                {
                    props: { variant: 'dashed' },
                    style: {
                        backgroundColor: customPalette.paper.background.default,
                        border: c('1px', Border.Dashed, customPalette.paper.border.dashed),
                        borderRadius: BorderRadius.M,
                    },
                },
                {
                    props: { variant: 'lightgray' },
                    style: {
                        opacity: 0.5,
                        backgroundColor: customPalette.paper.background.lightGray,
                        boxShadow: Shadow.None,
                    },
                },
                {
                    props: { variant: 'gray' },
                    style: {
                        backgroundColor: customPalette.paper.background.lightGray,
                        boxShadow: Shadow.None,
                    },
                },
            ],
            styleOverrides: {
                outlined: {
                    boxShadow: Shadow.Outline,
                    border: Border.None,
                },
                elevation1: {
                    boxShadow: Shadow.Elevation1,
                },
                elevation2: {
                    boxShadow: Shadow.Elevation2,
                },
                elevation3: {
                    boxShadow: Shadow.Elevation3,
                },
                elevation4: {
                    boxShadow: Shadow.Elevation4,
                },
            },
        },
        MuiButton: buttonStyles,
        MuiLoadingButton: buttonStyles,
        MuiToggleButton: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    color: customPalette.toggleGroup.color.default,
                    backgroundColor: customPalette.toggleGroup.background.default,
                    border: Border.None,
                    transition: Transition.ButtonBoxShadow,
                    '&:hover': {
                        boxShadow: Shadow.Elevation2,
                        backgroundColor: customPalette.toggleGroup.background.default,
                    },
                    '&.Mui-selected, &.Mui-selected.Mui-disabled': {
                        color: customPalette.toggleGroup.color.selected,
                        backgroundColor: customPalette.toggleGroup.background.selected,
                        boxShadow: Shadow.Elevation1,
                        border: Border.None,
                        '&:hover': {
                            boxShadow: Shadow.Elevation2,
                            backgroundColor: customPalette.toggleGroup.background.selected,
                        },
                    },
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    backgroundColor: customPalette.toggleGroup.background.default,
                    borderRadius: BorderRadius.M,
                    padding: 3,
                },
                groupedHorizontal: {
                    borderRadius: c(BorderRadius.M, STYLE_IMPORTANT),
                    '& + .MuiToggleButton-root': {
                        marginLeft: 4,
                    },
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    whiteSpace: 'nowrap',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: c('8px', '16px'),
                    overflowWrap: 'break-word',
                    maxHeight: 52,
                },
                head: Object.assign(Object.assign({}, typography.tablehead), { backgroundColor: customPalette.tableHead.background, '&:first-of-type': {
                        borderTopLeftRadius: BorderRadius.M,
                    }, '&:last-of-type': {
                        borderTopRightRadius: BorderRadius.M,
                    } }),
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    backgroundColor: customPalette.tableRow.background.main,
                    color: customPalette.tableRow.color,
                    '&.Mui-selected': {
                        backgroundColor: customPalette.tableRow.background.selected,
                    },
                    '&:hover, &.Mui-selected:hover': {
                        backgroundColor: c(customPalette.tableRow.background.hover, STYLE_IMPORTANT),
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: SHARED_SETTINGS.inputVariant,
            },
        },
        MuiInput: {
            defaultProps: {
                variant: SHARED_SETTINGS.inputVariant,
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    '& .MuiIconButton-root': {
                        color: customPalette.input.color.adornment,
                    },
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                variant: SHARED_SETTINGS.inputVariant,
            },
            styleOverrides: {
                shrink: {
                    padding: c('0px', '5px'),
                    lineHeight: 1,
                    backgroundColor: customPalette.inputLabel.background.default,
                },
                outlined: {
                    color: customPalette.inputLabel.color.default,
                    '&.Mui-focused': {
                        color: customPalette.inputLabel.color.focused,
                    },
                    '&.Mui-error': {
                        color: customPalette.inputLabel.color.error,
                    },
                },
                formControl: {
                    color: customPalette.inputLabel.color.default,
                    backgroundColor: c(customPalette.inputLabel.background.default, STYLE_IMPORTANT),
                    '&.Mui-focused': {
                        color: customPalette.inputLabel.color.focused,
                    },
                    '&.Mui-error': {
                        color: customPalette.inputLabel.color.error,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderColor: customPalette.input.border.default,
                    '&.Mui-error, &.Mui-error.Mui-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: customPalette.input.border.default,
                        },
                    },
                    '&.Mui-disabled': {
                        color: customPalette.input.color.default,
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: customPalette.input.border.default,
                            borderStyle: Border.Dotted,
                        },
                    },
                    '&.Mui-focused': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: customPalette.input.border.focused,
                        },
                    },
                },
                input: {
                    WebkitTextFillColor: c('unset', STYLE_IMPORTANT),
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: Object.assign({}, typography.body1),
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    margin: c('0px', STYLE_IMPORTANT),
                    color: customPalette.formHelperText.color.default,
                    '&.Mui-error': {
                        color: customPalette.formHelperText.color.error,
                    },
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: SHARED_SETTINGS.inputVariant,
            },
            styleOverrides: {
                icon: {
                    color: customPalette.input.color.adornment,
                },
                select: {
                    '&:focus': {
                        backgroundColor: customPalette.input.background.default,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: customPalette.radio.color.secondary,
                    '&.Mui-disabled': {
                        color: c(customPalette.radio.color.disabled, STYLE_IMPORTANT),
                    },
                },
                colorPrimary: {
                    '&.Mui-checked': {
                        color: customPalette.radio.color.default,
                    },
                },
                colorSecondary: {
                    '&.Mui-checked': {
                        color: customPalette.radio.color.default,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: customPalette.checkbox.color.secondary,
                    '&.Mui-disabled': {
                        color: c(customPalette.checkbox.color.disabled, STYLE_IMPORTANT),
                    },
                },
                colorPrimary: {
                    '&.Mui-checked': {
                        color: customPalette.checkbox.color.default,
                    },
                },
                colorSecondary: {
                    '&.Mui-checked': {
                        color: customPalette.checkbox.color.default,
                    },
                },
                indeterminate: {
                    '&.Mui-checked': {
                        color: c(customPalette.checkbox.color.indeterminate, STYLE_IMPORTANT),
                    },
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    '& > .MuiSwitch-switchBase.Mui-disabled': {
                        opacity: c('0.5', STYLE_IMPORTANT),
                    },
                },
                switchBase: {
                    color: c(customPalette.switch.color.off.primary, STYLE_IMPORTANT),
                    '&.Mui-checked': {
                        color: c(customPalette.switch.color.on.primary, STYLE_IMPORTANT),
                        '& .MuiSwitch-thumb': {
                            boxShadow: Shadow.ToggleOn(customPalette.switch.shadow.on),
                        },
                        '& + .MuiSwitch-track': {
                            backgroundColor: c(customPalette.switch.color.on.secondary, STYLE_IMPORTANT),
                        },
                    },
                },
                thumb: {
                    boxShadow: Shadow.ToggleOff,
                },
                track: {
                    opacity: 1,
                    backgroundColor: c(customPalette.switch.color.off.secondary, STYLE_IMPORTANT),
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    justifyContent: JustifyContent.FlexStart,
                    border: c('1px', Border.Solid, 'transparent'),
                },
                outlined: {
                    border: c('1px', Border.Solid, customPalette.chip.border),
                },
                deleteIcon: {
                    marginLeft: 'auto',
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    boxShadow: Shadow.Elevation3,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    boxShadow: Shadow.Elevation3,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: FontSize.Default,
                    '&.Mui-selected': {
                        backgroundColor: customPalette.menuItem.background.selected,
                    },
                    '&:hover, &.Mui-selected:hover': {
                        backgroundColor: customPalette.menuItem.background.hover,
                        color: customPalette.menuItem.color.hover,
                    },
                    '& > .MuiAvatar-root': {
                        maxHeight: 24,
                        maxWidth: 24,
                    },
                    '& > .MuiSvgIcon-root': {
                        maxHeight: 18,
                        maxWidth: 18,
                    },
                    '& > .MuiSvgIcon-root, & > .MuiAvatar-root': {
                        marginRight: '8px',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    height: 3,
                    backgroundColor: customPalette.tab.border.selected,
                },
            },
        },
        MuiTab: {
            defaultProps: {
                color: SHARED_SETTINGS.buttonColorPreset,
                variant: 'text',
            },
            styleOverrides: {
                root: Object.assign({}, typography.tabs),
                textColorPrimary: {
                    color: customPalette.tab.color.default,
                    borderBottom: c('1px', Border.Solid, customPalette.tab.border.default),
                    '&:hover, &.Mui-selected:hover': {
                        color: c(customPalette.tab.color.hover, STYLE_IMPORTANT),
                    },
                    '&.Mui-selected': {
                        color: c(customPalette.tab.color.selected, STYLE_IMPORTANT),
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: Shadow.Elevation1,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    boxShadow: Shadow.Elevation4,
                    borderRadius: BorderRadius.L,
                    '&, & *': {
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    },
                },
                container: {
                    backgroundColor: customPalette.overlay.background,
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                placement: 'top',
                arrow: true,
            },
            styleOverrides: {
                tooltip: {
                    backgroundColor: customPalette.tooltip.background.default,
                },
                arrow: {
                    color: customPalette.tooltip.background.default,
                },
            },
        },
    };
    const themeOptions = {
        palette,
        customPalette,
        typography,
        components,
        spacing: 8,
    };
    return createTheme(themeOptions);
};
export const theme = createMuiTheme();
export * from './constants';
export * from './utils';
