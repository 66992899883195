import { DateTime } from 'luxon';
/** Checks whether the given `value` is a instance of `DateTime` from `luxon`. */
export function isDateTime(value) {
    return value instanceof DateTime;
}
/** Checks whether the given `value` is a `string`. */
export function isStr(value) {
    return typeof value === 'string';
}
/** Checks whether the given `value` is a `object`. */
// eslint-disable-next-line @typescript-eslint/ban-types
export function isObj(value) {
    return typeof value === 'object';
}
/** Checks whether the given `attr` is a property of `value`. */
export function isProp(value, attr) {
    return Boolean(typeof value === 'object' && value && attr in value);
}
/** Checks whether the given `value` is a `object`. */
// eslint-disable-next-line @typescript-eslint/ban-types
export function isFn(value) {
    return typeof value === 'function';
}
/** Checks whether the given `value` is a `PromiseFulfilledResult<T>`. */
export function isPromiseSuccess(promise) {
    return promise.status === 'fulfilled';
}
/** Check the given `value` as valid JSON. */
export function isValidJSON(value) {
    try {
        JSON.parse(value);
        return true;
    }
    catch (err) {
        console.error(err);
    }
    return false;
}
