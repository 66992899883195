/** @deprecated Use {@link DonationFrecuency} instead. */
export var DonationType;
(function (DonationType) {
    DonationType["oneTime"] = "One-Time";
    DonationType["monthly"] = "Monthly";
    DonationType["quarterly"] = "Quarterly";
    DonationType["semiAnnually"] = "Semi-Annually";
    DonationType["annually"] = "Annually";
})(DonationType || (DonationType = {}));
export var DonationFrecuency;
(function (DonationFrecuency) {
    DonationFrecuency["ONE_TIME"] = "One-Time";
    DonationFrecuency["MONTHLY"] = "Monthly";
    DonationFrecuency["QUARTERLY"] = "Quarterly";
    DonationFrecuency["SEMI_ANNUALLY"] = "Semi-Annually";
    DonationFrecuency["ANNUALLY"] = "Annually";
    DonationFrecuency["RECURRING"] = "Recurring";
})(DonationFrecuency || (DonationFrecuency = {}));
export var DonorType;
(function (DonorType) {
    DonorType["individual"] = "individual";
    DonorType["organization"] = "organization";
})(DonorType || (DonorType = {}));
export const DONATION_FRECUENCIES = [
    { label: 'One-Time', value: DonationFrecuency.ONE_TIME },
    { label: 'Monthly', value: DonationFrecuency.MONTHLY },
    { label: 'Quarterly', value: DonationFrecuency.QUARTERLY },
    { label: 'Semi-Annually', value: DonationFrecuency.SEMI_ANNUALLY },
    { label: 'Annually', value: DonationFrecuency.ANNUALLY },
];
