/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
import { Locale, PHONE_MASK, PHONE_MASK_WILDCARD } from '@vizsla/constants';
export var FormatStyle;
(function (FormatStyle) {
    FormatStyle["Currency"] = "currency";
    FormatStyle["Percent"] = "percent";
})(FormatStyle || (FormatStyle = {}));
export function formatMoney(value = 0, minimumFractionDigits = 0) {
    const currency = 'USD';
    return Number(value).toLocaleString(Locale.US, {
        style: FormatStyle.Currency,
        currency,
        minimumFractionDigits,
    });
}
export function formatPercentage(value = 0, minimumFractionDigits = 0) {
    if (!Number.isFinite(value)) {
        return null;
    }
    const decimal = value / 100;
    return decimal.toLocaleString(Locale.US, {
        style: FormatStyle.Percent,
        minimumFractionDigits,
    });
}
export function calcPercentage(startNumber = 0, endNumber = 0) {
    if (!Number.isFinite(startNumber) || !Number.isFinite(endNumber)) {
        return 0;
    }
    if (endNumber <= 0 && startNumber > 0) {
        return 100;
    }
    if (endNumber <= 0) {
        return 0;
    }
    return Math.round((startNumber / endNumber) * 100);
}
export function formatNumber(value = 0) {
    return value.toLocaleString(Locale.US);
}
/**
 * Allow to format the given `phone` number using the `PHONE_MASK` and `PHONE_MASK_WILDCARD`;
 * @example `+1 (800) 000-0000`
 */
export function formatPhoneNumber(phone, OPTIONAL_PHONE_MASK = PHONE_MASK) {
    if (typeof phone !== 'string' && typeof phone !== 'number') {
        return undefined;
    }
    const text = String(phone);
    const withoutCountryCode = text.replace(/^\+[0-9]{1,3}\s+/i, '');
    let formatted = '';
    const size = OPTIONAL_PHONE_MASK.length;
    for (let index = 0, offset = 0; index < size; index++) {
        const char = withoutCountryCode[offset];
        const curr = OPTIONAL_PHONE_MASK[index];
        if (curr === PHONE_MASK_WILDCARD) {
            formatted += char;
            offset++;
            continue;
        }
        formatted += curr;
    }
    return formatted;
}
