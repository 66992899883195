var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CommitmentType } from '@vizsla/types';
import { calculateBaseDonationAmount } from './calculateBaseDonationAmount';
export const calculateCommitmentAmount = (baseAmount, remainingAmount, matchLimit) => {
    const matchedAmount = Math.min(remainingAmount, Math.min(matchLimit, baseAmount));
    return { matchedAmount };
};
export const generatePartnershipMatchCommitment = (donationMatchData, createPartnershipCommitment, updatePartnershipCommitmentMutation) => __awaiter(void 0, void 0, void 0, function* () {
    const { matchingCommitments, donationAmount, fundraiser, team, experienceId, campaignId, donorId, frecuency, startDate, endDate, } = donationMatchData;
    const partnershipCommitmentsResponse = [];
    const updateCommitmentsResponse = [];
    matchingCommitments === null || matchingCommitments === void 0 ? void 0 : matchingCommitments.forEach((matchingCommitment) => __awaiter(void 0, void 0, void 0, function* () {
        const { id: commitmentId, amount: totalAmount, startDate: commitmentStartDate, endDate: commitmentEndDate, partnershipName, matchLimit, usedAmount, } = matchingCommitment || {};
        const currentDate = new Date();
        if (commitmentEndDate) {
            const convertedStartDate = new Date(commitmentStartDate);
            const convertedEndDate = new Date(commitmentEndDate);
            if (currentDate < convertedStartDate || currentDate > convertedEndDate) {
                return;
            }
        }
        else {
            const convertedStartDate = new Date(commitmentStartDate);
            if (currentDate < convertedStartDate) {
                return;
            }
        }
        const baseAmount = calculateBaseDonationAmount(donationAmount, frecuency, startDate, endDate);
        const remainingAmount = totalAmount - (usedAmount || 0);
        const { matchedAmount } = calculateCommitmentAmount(baseAmount, remainingAmount, matchLimit);
        const currentRemainingAmount = totalAmount - ((usedAmount || 0) + matchedAmount);
        let createCommitmentResponse;
        let updateCommitmentResponse;
        try {
            createCommitmentResponse = yield createPartnershipCommitment({
                amount: matchedAmount,
                company: partnershipName,
                fundraiser: fundraiser ? { connect: { id: fundraiser.id } } : null,
                team: team ? { connect: { id: team.id } } : null,
                status: false,
                type: CommitmentType.PartnershipMatching,
                experience: { connect: { id: experienceId } },
                campaign: { connect: { id: campaignId } },
                donor: { connect: { id: donorId } },
                frequency: frecuency,
                partnership: { connect: { id: commitmentId } },
            });
            partnershipCommitmentsResponse.push(createCommitmentResponse);
        }
        catch (error) {
            console.error('Error while creating partnership commitment:', error);
            createCommitmentResponse = error;
        }
        try {
            yield updatePartnershipCommitmentMutation({
                variables: {
                    data: {
                        usedAmount: ((usedAmount || 0) + matchedAmount),
                        status: currentRemainingAmount <= 0.01 ? 'paid' : 'pending',
                    },
                    filter: { id: commitmentId },
                },
            });
            updateCommitmentsResponse.push(updateCommitmentResponse);
        }
        catch (error) {
            console.error('Error while updating partnership commitment:', error);
            updateCommitmentResponse = error;
        }
    }));
    return { partnershipCommitmentsResponse, updateCommitmentsResponse };
});
