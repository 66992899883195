const EMAIL_EXTRACTOR_REGEX = /^(?<begin>[a-z0-9!#$%&'*./=?^_`{|}~-]+)(?:\+(?<middle>[a-z0-9!#$%&'*/=?^_`{|}~-]+))*@(?<domain>.+)$/i;
/**
 * Generate the minor email based on the given `parentEmail`.
 * This **DO NOT** validate the given email as valid, only extracts the needed parts to generate an email.
 * @param parentEmail Email of the selected parent to generate the minor email.
 * @url https://8base-dev.atlassian.net/browse/VSL-2085
 */
export function generateMinorEmail(parentEmail, options) {
    var _a, _b, _c;
    const matches = parentEmail.match(EMAIL_EXTRACTOR_REGEX);
    if (matches === null) {
        return null;
    }
    const enumerator = (_a = generateUniqueMinor(options === null || options === void 0 ? void 0 : options.enumerator)) !== null && _a !== void 0 ? _a : generateUniqueMinor('minor');
    const begin = (_b = matches.groups) === null || _b === void 0 ? void 0 : _b.begin;
    const domain = (_c = matches.groups) === null || _c === void 0 ? void 0 : _c.domain;
    return `${begin}+${enumerator}@${domain}`;
}
export const generateUniqueMinor = (minor) => {
    const id = Math.random().toString(36).substring(2, 8);
    return minor + id;
};
