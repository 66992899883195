/**
 * Normalize the given `CustomQuestionFragment` to be `Question`.
 * https://8base-dev.atlassian.net/browse/VSL-2063
 * @returns A object that satisfies `Question`.
 */
export function normalizeCustomQuestion(data) {
    var _a;
    const id = data.id;
    const name = `questions.${id}`;
    const type = data.type;
    const format = data.format;
    const category = data.category;
    const title = data.title;
    const options = (_a = data.options) !== null && _a !== void 0 ? _a : [];
    const question = {
        id,
        type,
        format,
        name,
        title,
        options: options,
        category,
        isMandatory: false,
    };
    return question;
}
