var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useCreatePaymentIntentForItem } from './useCreatePaymentIntentForItem';
import { useCurrentStripeData } from './useCurrentStripeData';
export const useConfirmCardPayment = () => {
    const stripe = useStripe();
    const elements = useElements();
    const { stripeAccountID } = useCurrentStripeData();
    const [confirmingCardPayment, setConfirmingCardPayment] = React.useState(false);
    const { createPaymentIntentForItem, loading: creatingPaymentIntent } = useCreatePaymentIntentForItem(String(stripeAccountID));
    const confirmCardPayment = React.useCallback(({ payerInfo, price, meta = {}, }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const email = (_a = payerInfo.email) !== null && _a !== void 0 ? _a : undefined;
        const { paymentIntent: newIntent } = yield createPaymentIntentForItem(price, meta, email);
        if (!stripe || !elements || !newIntent) {
            throw new Error('Cannot load Stripe');
        }
        const secret = newIntent === null || newIntent === void 0 ? void 0 : newIntent.client_secret;
        const card = elements.getElement(CardNumberElement);
        setConfirmingCardPayment(true);
        const { paymentIntent, error } = yield stripe.confirmCardPayment(secret, {
            payment_method: {
                card,
                billing_details: {
                    name: `${payerInfo.firstName} ${payerInfo.firstName}`,
                    email,
                    phone: `+1${payerInfo.phone}`,
                    address: payerInfo.billingAddress,
                },
            },
        });
        setConfirmingCardPayment(false);
        const resultStatus = paymentIntent === null || paymentIntent === void 0 ? void 0 : paymentIntent.status;
        if (error) {
            throw new Error(`Cannot confirm card payment: ${error}`);
        }
        if (resultStatus === 'succeeded') {
            return { stripeId: (paymentIntent === null || paymentIntent === void 0 ? void 0 : paymentIntent.id) || '', status: resultStatus };
        }
        throw new Error('Something went wrong');
    }), [createPaymentIntentForItem, elements, stripe]);
    return {
        confirmCardPayment,
        loading: creatingPaymentIntent || confirmingCardPayment,
    };
};
