export const TAXES_CODES = {
    donation: 'txcd_90000001',
    asset: 'txcd_30021000',
};
export const formatTaxes = (items) => {
    return items.map(item => {
        const itemFormat = {
            amount: Math.ceil(item.price * 100),
            reference: item.id,
        };
        if (item.type === 'donation') {
            itemFormat.tax_code = TAXES_CODES.donation;
        }
        if (item.type === 'asset') {
            itemFormat.tax_code = TAXES_CODES.asset;
        }
        return itemFormat;
    });
};
export const AVALARA_TAXES_CODES_DEPRECATE = {
    registration: 'ON030000',
    asset: 'PP198874',
    donation: 'OM010000',
};
export const AVALARA_TAXES_CODES = {
    vizslaFee: 'SD020902',
};
export const formatTaxesAvalara = (items) => {
    return items.map(item => {
        const itemFormat = {
            amount: item.price,
            reference: item.id,
            itemCode: item.id,
            quantity: 1,
        };
        if (item.type in AVALARA_TAXES_CODES) {
            itemFormat.taxCode = AVALARA_TAXES_CODES[item.type];
        }
        return itemFormat;
    });
};
// TODO: Define collector's billing address from the experience when it's defined in the admin.
export const getTaxesBillingData = (customerInfo, experience) => {
    var _a, _b, _c, _d, _e;
    return {
        collector: {
            line1: '172 Trademark St',
            city: 'Buda',
            postalCode: '78610',
            state: 'TX',
        },
        customer: {
            name: customerInfo.name,
            line1: ((_a = customerInfo.address) === null || _a === void 0 ? void 0 : _a.line1) || '',
            line2: (_b = customerInfo.address) === null || _b === void 0 ? void 0 : _b.line2,
            city: ((_c = customerInfo.address) === null || _c === void 0 ? void 0 : _c.city) || '',
            postalCode: ((_d = customerInfo.address) === null || _d === void 0 ? void 0 : _d.postal_code) || '',
            state: ((_e = customerInfo.address) === null || _e === void 0 ? void 0 : _e.state) || '',
        },
    };
};
