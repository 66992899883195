/** Opens the given `url` as download. */
export function openDownloadURL(url, filename) {
    const attr = document.createElement('a');
    attr.setAttribute('href', url.toString());
    attr.setAttribute('target', '_blank');
    attr.setAttribute('download', filename);
    document.body.appendChild(attr);
    attr.click();
    document.body.removeChild(attr);
}
/**
 * Function to save a file to the user's computer from web browser
 */
export function saveFile(file, filename) {
    const url = URL.createObjectURL(file);
    openDownloadURL(url, filename);
}
