import { DiscountAmountTypes, } from '@vizsla/types';
function totalize(cart) {
    return cart.reduce((acc, prev) => acc + prev.price, 0);
}
export function calculateShoppingCartSubTotal(cart) {
    const items = cart.filter(item => item.type !== 'discount' &&
        item.type !== 'tax' &&
        item.type !== 'vizslaFee' &&
        item.type !== 'paymentFee' &&
        item.type !== 'roundup');
    return totalize(items);
}
/**
 * Apply all the rules of [VSL-794](https://8base-dev.atlassian.net/browse/VSL-794) to totalize the discount amount.
 */
export function calculateShoppingCartDiscountTotal(cart) {
    var _a;
    const registrations = cart.filter(i => i.type === 'registration');
    const discount = cart.find(i => i.type === 'discount');
    const subtotal = totalize(registrations);
    if (!(discount === null || discount === void 0 ? void 0 : discount.settings))
        return 0;
    switch ((_a = discount === null || discount === void 0 ? void 0 : discount.settings) === null || _a === void 0 ? void 0 : _a.amountType) {
        case DiscountAmountTypes.dollar: {
            const amount = Math.max(discount.settings.amount, 0);
            return amount;
        }
        case DiscountAmountTypes.percentage: {
            const percentage = Math.max(discount.settings.amount, 0) / 100;
            return subtotal * percentage;
        }
        default: {
            return 0;
        }
    }
}
export function calculateShoppingCartTaxes(cart) {
    const items = cart.filter(item => item.type === 'tax');
    return totalize(items);
}
/** @deprecated Use `calculateShoppingCartTaxes` instead. */
export const getTaxes = calculateShoppingCartTaxes;
export function calculateShoppingCartPlatformFee(cart) {
    const items = cart.filter(item => item.type === 'vizslaFee');
    return totalize(items);
}
/** @deprecated Use `calculateShoppingCartPlatformFee` instead. */
export const getVizslaFee = calculateShoppingCartPlatformFee;
export function calculateShoppingCartProcessingFee(cart) {
    const items = cart.filter(item => item.type === 'paymentFee');
    return totalize(items);
}
/** @deprecated Use `calculateShoppingCartProcessingFee` instead. */
export const getPaymentFee = calculateShoppingCartProcessingFee;
export const getRoundup = (cart) => {
    const items = cart.filter(i => i.type === 'roundup');
    return totalize(items);
};
export function calculateShoppingCartMerchandise(cart) {
    const items = cart.filter(item => item.type === 'asset');
    return totalize(items);
}
export function calculateShoppingCartTotal(cart) {
    const subtotal = calculateShoppingCartSubTotal(cart);
    const discounts = calculateShoppingCartDiscountTotal(cart);
    const taxes = calculateShoppingCartTaxes(cart);
    const platformFee = calculateShoppingCartPlatformFee(cart);
    const processingFee = calculateShoppingCartProcessingFee(cart);
    const roundup = getRoundup(cart);
    const subTotalDiscount = subtotal - discounts;
    const fees = platformFee + processingFee;
    const total = subTotalDiscount + taxes + fees + roundup;
    if (subtotal === 0) {
        // Only when you have elements to pay you will have to charge.
        return 0;
    }
    if (subTotalDiscount <= 0) {
        return 0;
    }
    return total;
}
export const calculateShoppingCartTotalWithoutDonation = (cart) => {
    const items = cart.filter(item => item.type !== 'donation' &&
        item.type !== 'discount' &&
        item.type !== 'tax' &&
        item.type !== 'vizslaFee' &&
        item.type !== 'paymentFee' &&
        item.type !== 'roundup');
    const discounts = calculateShoppingCartDiscountTotal(cart);
    return totalize(items) - discounts;
};
export const calculateShoppingCartItemsTotal = (cart) => {
    const items = cart.filter(item => item.type !== 'discount' &&
        item.type !== 'tax' &&
        item.type !== 'vizslaFee' &&
        item.type !== 'paymentFee' &&
        item.type !== 'roundup');
    const discounts = calculateShoppingCartDiscountTotal(cart);
    return totalize(items) - discounts;
};
export const calculateShoppingCartTotalWitOutPaymentFee = (cart) => {
    const items = cart.filter(item => item.type !== 'discount' && item.type !== 'paymentFee');
    const discounts = calculateShoppingCartDiscountTotal(cart);
    return totalize(items) - discounts;
};
export const calculateShoppingCartTotalWitOutPaymentFeeAndDonation = (cart) => {
    const items = cart.filter(item => item.type !== 'discount' && item.type !== 'paymentFee' && item.type !== 'donation');
    const discounts = calculateShoppingCartDiscountTotal(cart);
    return totalize(items) - discounts;
};
export const calculateDiscount = (cart) => {
    const discount = cart.filter(item => item.type === 'discount')[0];
    if (discount) {
        return discount.settings.amount;
    }
    return 0;
};
