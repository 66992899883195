import { DonationFrecuency } from '@vizsla/types';
export const calculateBaseDonationAmount = (baseAmount, frecuency, startDate, endDate) => {
    let totalAmount = baseAmount;
    if (frecuency && startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffInMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
        switch (frecuency) {
            case DonationFrecuency.ONE_TIME:
                totalAmount = baseAmount;
                break;
            case DonationFrecuency.MONTHLY:
                totalAmount = baseAmount * diffInMonths;
                break;
            case DonationFrecuency.QUARTERLY:
                totalAmount = baseAmount * Math.ceil(diffInMonths / 3);
                break;
            case DonationFrecuency.SEMI_ANNUALLY:
                totalAmount = baseAmount * Math.ceil(diffInMonths / 6);
                break;
            case DonationFrecuency.ANNUALLY:
                totalAmount = baseAmount * Math.ceil(diffInMonths / 12);
                break;
            default:
                throw new Error('Invalid frecuency');
        }
    }
    return totalAmount;
};
