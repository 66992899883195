import _ from 'lodash';
import { QUESTION_CATEGORIES_ORDER, QUESTION_TYPE_TO_DEFAULT_FORMAT } from '@vizsla/constants';
import { CampaignQuestionCategories, CampaignQuestionFormat, CampaignQuestionInputType, } from '@vizsla/types';
export function isEnabledAllCampaignQuestions(campaignQuestions, userGroup) {
    const isEnabledGroup = `isEnabled${userGroup}`;
    return _.findIndex(campaignQuestions, { [isEnabledGroup]: false }) === -1;
}
export function filterDefaultQuestionsByUserGroup(defaultQuestions, userGroup) {
    return defaultQuestions.filter(defaultQuestion => { var _a, _b; return (_b = (_a = defaultQuestion === null || defaultQuestion === void 0 ? void 0 : defaultQuestion.question) === null || _a === void 0 ? void 0 : _a.userGroup) === null || _b === void 0 ? void 0 : _b.includes(userGroup); });
}
export function filterCustomQuestionsByUserGroup(customQuestions, userGroup) {
    return customQuestions.filter(question => { var _a; return (_a = question === null || question === void 0 ? void 0 : question.userGroup) === null || _a === void 0 ? void 0 : _a.includes(userGroup); });
}
export function filterQuestionsByUserGroup(campaignQuestions, userGroup) {
    return campaignQuestions.filter(q => {
        var _a, _b, _c;
        const questionUserGroup = (_c = (_a = q === null || q === void 0 ? void 0 : q.userGroup) !== null && _a !== void 0 ? _a : (_b = q === null || q === void 0 ? void 0 : q.question) === null || _b === void 0 ? void 0 : _b.userGroup) !== null && _c !== void 0 ? _c : [];
        return questionUserGroup.includes(userGroup);
    });
}
export const sortQuestionsByOrder = (questions) => {
    return _.sortBy(questions, 'order');
};
export const sortQuestionsByMandatoryFirst = (questions) => {
    return _.orderBy(questions, 'question.isMandatory', 'desc');
};
export const getManagedQuestionGroups = (defaultQuestions, customQuestions) => {
    const defaultMandatoryQuestions = [];
    const defaultOptionalQuestions = [];
    defaultQuestions.forEach(q => {
        var _a;
        const isMandatory = (_a = q === null || q === void 0 ? void 0 : q.question) === null || _a === void 0 ? void 0 : _a.isMandatory;
        const targetArray = isMandatory ? defaultMandatoryQuestions : defaultOptionalQuestions;
        targetArray.push(q);
    });
    const remainingQuestions = [...defaultOptionalQuestions, ...customQuestions];
    const questionGroupsMap = {};
    remainingQuestions.forEach((q) => {
        var _a, _b, _c;
        const category = (_c = (_a = q === null || q === void 0 ? void 0 : q.category) !== null && _a !== void 0 ? _a : (_b = q === null || q === void 0 ? void 0 : q.question) === null || _b === void 0 ? void 0 : _b.category) !== null && _c !== void 0 ? _c : CampaignQuestionCategories.Other;
        const targetGroup = _.get(questionGroupsMap, [category], []);
        targetGroup.push(q);
        _.set(questionGroupsMap, [category], targetGroup);
    });
    const managedGroups = QUESTION_CATEGORIES_ORDER.map(category => ({
        category,
        questions: _.get(questionGroupsMap, [category], []),
    }));
    return [
        { category: CampaignQuestionCategories.Mandatory, questions: defaultMandatoryQuestions },
        ...managedGroups,
    ];
};
export const groupQuestionsByCategory = (defaultQuestions, customQuestions) => {
    const campaignQuestions = [...defaultQuestions, ...customQuestions];
    const questionGroupsMap = {};
    campaignQuestions.forEach((q) => {
        var _a, _b, _c;
        const category = (_c = (_a = q === null || q === void 0 ? void 0 : q.category) !== null && _a !== void 0 ? _a : (_b = q === null || q === void 0 ? void 0 : q.question) === null || _b === void 0 ? void 0 : _b.category) !== null && _c !== void 0 ? _c : CampaignQuestionCategories.Other;
        const targetGroup = _.get(questionGroupsMap, [category], []);
        targetGroup.push(q);
        _.set(questionGroupsMap, [category], targetGroup);
    });
    const questionGroups = QUESTION_CATEGORIES_ORDER.map(category => ({
        category,
        questions: _.get(questionGroupsMap, [category], []),
    }));
    return questionGroups;
};
export const prepareCustomQuestionUpdateInput = (campaignQuestionData) => {
    var _a, _b;
    const experiencesKeyFilter = (_b = (_a = campaignQuestionData === null || campaignQuestionData === void 0 ? void 0 : campaignQuestionData.experience) === null || _a === void 0 ? void 0 : _a.map(id => ({
        id,
    }))) !== null && _b !== void 0 ? _b : [];
    if (campaignQuestionData.type === CampaignQuestionInputType.checkbox) {
        campaignQuestionData.format = CampaignQuestionFormat.multipleChoice;
    }
    return Object.assign(Object.assign({}, campaignQuestionData), { experience: {
            reconnect: experiencesKeyFilter,
        } });
};
export const prepareCustomQuestionCreateInput = (campaignQuestionData) => {
    var _a, _b, _c;
    const campaignId = campaignQuestionData === null || campaignQuestionData === void 0 ? void 0 : campaignQuestionData.campaign;
    const experiencesKeyFilter = (_b = (_a = campaignQuestionData === null || campaignQuestionData === void 0 ? void 0 : campaignQuestionData.experience) === null || _a === void 0 ? void 0 : _a.map(id => ({
        id,
    }))) !== null && _b !== void 0 ? _b : [];
    const type = ((_c = campaignQuestionData === null || campaignQuestionData === void 0 ? void 0 : campaignQuestionData.type) !== null && _c !== void 0 ? _c : CampaignQuestionInputType.text);
    const format = (campaignQuestionData === null || campaignQuestionData === void 0 ? void 0 : campaignQuestionData.format) || QUESTION_TYPE_TO_DEFAULT_FORMAT[type];
    return Object.assign(Object.assign({}, campaignQuestionData), { format, experience: {
            connect: experiencesKeyFilter,
        }, campaign: {
            connect: {
                id: campaignId,
            },
        } });
};
export const updateOptionalQuestionsGroup = (localOptionalQuestionsGroups, category, updatedPropValue, updatedProp, userGroup, campaignQuestion) => {
    const updatedGroupProp = `${updatedProp}${userGroup}`;
    const isEnabledGroup = `isEnabled${userGroup}`;
    return localOptionalQuestionsGroups.map(group => {
        if (group.category === category) {
            const updatedQuestions = group.questions.map(question => {
                if (updatedProp !== 'toggleEnableAll') {
                    if (question.id === (campaignQuestion === null || campaignQuestion === void 0 ? void 0 : campaignQuestion.id)) {
                        return Object.assign(Object.assign({}, question), { [updatedGroupProp]: updatedPropValue });
                    }
                    return question;
                }
                return Object.assign(Object.assign({}, question), { [isEnabledGroup]: updatedPropValue });
            });
            return Object.assign(Object.assign({}, group), { questions: updatedQuestions });
        }
        return group;
    });
};
export function findModifiedQuestions(localQuestionsGroups, storedQuestionsGroups) {
    const differentQuestions = [];
    localQuestionsGroups.forEach((localQuestions, index) => {
        const storedQuestions = storedQuestionsGroups[index];
        if (localQuestions.category === storedQuestions.category) {
            const diff = localQuestions.questions.filter((question, count) => !_.isEqual(question, storedQuestions.questions[count]));
            if (diff.length > 0) {
                differentQuestions.push(...diff);
            }
        }
    });
    return differentQuestions;
}
