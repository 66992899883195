var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isNil } from 'lodash';
import { DateTime, Settings } from 'luxon';
import { DateFormatPatterns, Locale } from '@vizsla/constants';
import { formatSQLDate, isDateBetween, parseDate } from './dates';
const DATE_TIME_SIMPLE_FORMAT = 'hh:mm a';
Settings.defaultLocale = Locale.US;
export const getDateFromISO = (stringDate) => DateTime.fromISO(stringDate);
export const getFullMonthDayYearFormat = (date) => date.toFormat(DateFormatPatterns.fullMonthDayYear);
export const getFullMonthDayYearHourTimeFormat = (date) => {
    return `${date.toFormat(DateFormatPatterns.fullMonthDayYear)} | ${getHourTimeFormat(date)}`;
};
export const getFullMonthDayFormat = (date) => date.toFormat(DateFormatPatterns.fullMonthDay);
export const getHourTimeFormat = (date) => date.toFormat(DateFormatPatterns.meridiemTimePadded);
export const geDateFromMonthWithTimeFormat = (date) => date.toFormat(DateFormatPatterns.fullMonthWithTime);
export const geDateFromMonthFormat = (date) => date.toFormat(DateFormatPatterns.fullDateStartsFromMonth);
export function getStringifiedDateRangeWithHours(start, end) {
    if (isNil(start))
        return '-';
    const startDate = getDateFromISO(start);
    if (isNil(end))
        return geDateFromMonthWithTimeFormat(startDate);
    const endDate = getDateFromISO(end);
    const initialHour = getHourTimeFormat(startDate);
    const finalHour = getHourTimeFormat(endDate);
    if (getFullMonthDayFormat(startDate) === getFullMonthDayFormat(endDate)) {
        return `${geDateFromMonthFormat(startDate)} at ${initialHour} to ${finalHour}`;
    }
    if (startDate.year === endDate.year) {
        return `${geDateFromMonthWithTimeFormat(startDate)} - ${geDateFromMonthWithTimeFormat(endDate)}`;
    }
    return `${geDateFromMonthWithTimeFormat(startDate)} - ${geDateFromMonthWithTimeFormat(endDate)}`;
}
export function getStringifiedDateRange(start, end) {
    if (isNil(start))
        return '-';
    const startDate = getDateFromISO(start);
    if (isNil(end))
        return getFullMonthDayYearFormat(startDate);
    const endDate = getDateFromISO(end);
    if (startDate.year === endDate.year) {
        const formattedStartDate = getFullMonthDayFormat(startDate);
        const formattedEndDate = getFullMonthDayYearFormat(endDate);
        return `${formattedStartDate} - ${formattedEndDate}`;
    }
    const formattedStartDate = getFullMonthDayYearFormat(startDate);
    const formattedEndDate = getFullMonthDayYearFormat(endDate);
    return `${formattedStartDate} - ${formattedEndDate}`;
}
export const saveTimeZoneToCache = (timeZone) => {
    timeZone && localStorage.setItem('userTimeZone', timeZone);
};
export const getTimeZoneFromCache = () => {
    var _a;
    return (_a = localStorage.getItem('userTimeZone')) !== null && _a !== void 0 ? _a : '';
};
const timeZoneMapping = {
    'America/New_York': 'Eastern Time (EST/EDT)',
    'America/Detroit': 'Eastern Time (EST/EDT)',
    'America/Chicago': 'Central Time (CST/CDT)',
    'America/Denver': 'Mountain Time (MST/MDT)',
    'America/Los_Angeles': 'Pacific Time (PST/PDT)',
    'America/Anchorage': 'Alaska Time',
    'Pacific/Honolulu': 'Hawaii Time',
    'America/Phoenix': 'Mountain Time (MST/MDT)',
    'Etc/GMT+5': 'Eastern Time (EST/EDT)',
    'Etc/GMT+6': 'Central Time (CST/CDT)',
    'Etc/GMT+7': 'Mountain Time (MST/MDT)',
    'Etc/GMT+8': 'Pacific Time (PST/PDT)',
    'Etc/GMT+9': 'Alaska Time',
    'Etc/GMT+10': 'Hawaii-Aleutian Time',
    'Etc/GMT+3': 'Atlantic Time',
    'Etc/GMT+4': 'Atlantic Time',
};
export const getFriendlyTimeZone = (timeZone) => {
    return timeZoneMapping[timeZone] || timeZone;
};
export const TIME_MAP_ZONE = {
    'Eastern Time (EST/EDT)': 'America/New_York',
    'Central Time(CST/CDT)': 'America/Chicago',
    'Mountain Time (MST/MDT)': 'America/Denver',
    'Pacific Time (PST/PDT)': 'America/Los_Angeles',
    'Alaska Time': 'America/Anchorage',
    'Hawaii Time': 'Pacific/Honolulu',
    UTC: 'utc',
};
var TimeZoneMap;
(function (TimeZoneMap) {
    TimeZoneMap["Eastern Time (EST/EDT)"] = "America/New_York";
    TimeZoneMap["Central Time (CST/CDT)"] = "America/Chicago";
    TimeZoneMap["Mountain Time (MST/MDT)"] = "America/Denver";
    TimeZoneMap["Pacific Time (PST/PDT)"] = "America/Los_Angeles";
    TimeZoneMap["Alaska Time"] = "America/Anchorage";
    TimeZoneMap["Hawaii Time"] = "Pacific/Honolulu";
    TimeZoneMap["UTC"] = "utc";
})(TimeZoneMap || (TimeZoneMap = {}));
/**
 * Retrieves the current user's time zone from the system settings and saves it to local storage.
 * @returns {string} The current user's time zone.
 */
export const getCurrentTimeZone = () => {
    const currentUserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    saveTimeZoneToCache(currentUserTimeZone);
    return currentUserTimeZone;
};
/**
 * Gets the default time zone by checking the cache first, then system settings, or defaults to 'utc'.
 * @returns {string} The default time zone.
 */
const getDefaultTimeZone = () => getTimeZoneFromCache() || getCurrentTimeZone() || 'utc';
/**
 * Converts a UTC date string to a local date string based on the default time zone.
 * @param {string} date - The UTC date string in ISO format.
 * @returns {string} The local date string in ISO format without the time zone offset.
 */
export const convertFromUTCGeo = (date, targetTimeZone, offSet = false) => {
    const timeZone = TIME_MAP_ZONE[targetTimeZone !== null && targetTimeZone !== void 0 ? targetTimeZone : ''] || targetTimeZone || getDefaultTimeZone();
    const dateUTC = DateTime.fromISO(date, { zone: 'utc' });
    const localDateTime = dateUTC.setZone(timeZone);
    return localDateTime.toISO({ includeOffset: offSet });
};
/**
 * Converts a local date string to a UTC date string based on the default time zone.
 * @param {string} date - The local date string in ISO format.
 * @returns {string} The UTC date string in ISO format with the time zone offset.
 */
export const convertToUTCGeo = (date, targetTimeZone) => {
    const timeZone = TIME_MAP_ZONE[targetTimeZone !== null && targetTimeZone !== void 0 ? targetTimeZone : ''] || targetTimeZone || getDefaultTimeZone();
    const localDateTime = DateTime.fromISO(date, { zone: timeZone });
    const dateUTC = localDateTime.setZone('utc');
    return dateUTC.toISO({ includeOffset: true });
};
/**
 * Converts a date string from UTC to a specified time zone.
 * @param {string} date - The UTC date string in ISO format.
 * @param {string} timeZone - The target time zone.
 * @returns {string} The date string in the target time zone in ISO format without the time zone offset.
 */
export const convertFromUTC = (date, timeZone) => {
    var _a;
    const dateUTC = DateTime.fromISO(date, { zone: 'utc' });
    const dateTimeZone = dateUTC.setZone((_a = TIME_MAP_ZONE[timeZone]) !== null && _a !== void 0 ? _a : timeZone);
    return dateTimeZone.toISO({ includeOffset: false });
};
/**
 * Converts a local date string to a UTC date string based on a specified time zone.
 * @param {string} date - The local date string in ISO format.
 * @param {string} timeZone - The local time zone.
 * @returns {string} The UTC date string in ISO format with the time zone offset.
 */
export const convertToUTC = (date, timeZone) => {
    var _a;
    const dateTimeZone = DateTime.fromISO(date, { zone: (_a = TIME_MAP_ZONE[timeZone]) !== null && _a !== void 0 ? _a : timeZone });
    const dateUTC = dateTimeZone.setZone('utc');
    return dateUTC.toISO({ includeOffset: true });
};
export function concatDateWithTime(date, time, offset = true) {
    if (isNil(date)) {
        return null;
    }
    if (isNil(time)) {
        return date;
    }
    const startDateObject = DateTime.fromISO(date).toObject();
    const startTimeObject = DateTime.fromISO(time).toObject();
    const dateTime = DateTime.fromObject(Object.assign(Object.assign({}, startDateObject), { hour: startTimeObject.hour, minute: startTimeObject.minute })).toISO({ includeOffset: offset });
    return dateTime;
}
export function concatDateWithTimeInObj(obj, timeZone) {
    const { startDate, startTime, endTime, endDate } = obj, rest = __rest(obj, ["startDate", "startTime", "endTime", "endDate"]);
    return Object.assign(Object.assign({}, rest), { startDate: convertToUTC(concatDateWithTime(startDate, startTime, false), timeZone), endDate: convertToUTC(concatDateWithTime(endDate, endTime, false), timeZone) });
}
/** @deprecated STOP USING THIS FORMATTER */
export function formatDate(date, toFormatPattern, fromFormatPattern) {
    if (isNil(date)) {
        return null;
    }
    if (isNil(fromFormatPattern)) {
        return DateTime.fromISO(date).toFormat(toFormatPattern);
    }
    return DateTime.fromFormat(date, fromFormatPattern).toFormat(toFormatPattern);
}
/** @deprecated Use `formatSQLDate` instead. */
export function formatToSQLDate(value) {
    return formatSQLDate(value);
}
export function formatDateIntl(date, options) {
    if (!date)
        return null;
    return DateTime.fromISO(date).toLocaleString(options);
}
export function getNowDate() {
    return DateTime.local().toISODate();
}
export function getNowDateTime() {
    return DateTime.local().toISO();
}
/** @deprecated Use `parseDate` instead. */
export function toDateTimeFromISO(dateISO) {
    if (isNil(dateISO)) {
        return null;
    }
    return DateTime.fromISO(dateISO);
}
/** @deprecated Use `formatMilliseconds` instead. */
export function getEpochFromDate(dateISO) {
    if (isNil(dateISO)) {
        return null;
    }
    return DateTime.fromISO(dateISO).toMillis();
}
export function isValidDate(date) {
    if (isNil(date)) {
        return false;
    }
    return DateTime.fromISO(date).isValid;
}
export function trimTime(date) {
    if (isNil(date)) {
        return null;
    }
    return DateTime.fromISO(date).toISODate();
}
export function trimDate(date) {
    if (isNil(date)) {
        return null;
    }
    return DateTime.fromISO(date).toISOTime();
}
export function isDateEquals(d1, d2, unit = 'day') {
    if (isNil(d1) || isNil(d2)) {
        return false;
    }
    const dateA = DateTime.fromISO(d1).startOf(unit);
    const dateB = DateTime.fromISO(d2).startOf(unit);
    return dateA.equals(dateB);
}
export function isDateGreaterThan(d1, d2, unit = 'day') {
    if (isNil(d1) || isNil(d2)) {
        return false;
    }
    const dateA = DateTime.fromISO(d1).startOf(unit);
    const dateB = DateTime.fromISO(d2).startOf(unit);
    return dateA > dateB;
}
export function isDateGreaterOrEquals(dateA, dateB, unit = 'day') {
    if (isNil(dateA) || isNil(dateB)) {
        return false;
    }
    return DateTime.fromISO(dateA).startOf(unit) >= DateTime.fromISO(dateB).startOf(unit);
}
export function isDateLessOrEquals(dateA, dateB, unit = 'day') {
    if (isNil(dateA) || isNil(dateB)) {
        return false;
    }
    return DateTime.fromISO(dateA).startOf(unit) <= DateTime.fromISO(dateB).startOf(unit);
}
export function getStartOf(date, unit = 'month') {
    return DateTime.fromISO(date).startOf(unit).toISODate();
}
export function getEndOf(date, unit = 'month') {
    return DateTime.fromISO(date).endOf(unit).toISODate();
}
export function getStartOfMonth(date) {
    return getStartOf(date, 'month');
}
export function getStartOfWeek(date) {
    return getStartOf(date, 'week');
}
export function getStartOfYear(date) {
    return getStartOf(date, 'year');
}
export function getEndOfWeek(date) {
    return getEndOf(date, 'week');
}
export function getEndOfMonth(date) {
    return getEndOf(date, 'month');
}
export function getEndOfYear(date) {
    return getEndOf(date, 'year');
}
export function getDaysIn(dateISO, unit) {
    if (unit === 'month') {
        return DateTime.fromISO(dateISO).daysInMonth;
    }
    return DateTime.fromISO(dateISO).daysInYear;
}
export function getMaxDateTime(dateA, dateB) {
    if (isNil(dateA) && isNil(dateB)) {
        return null;
    }
    if (isNil(dateA)) {
        return dateB;
    }
    if (isNil(dateB)) {
        return dateA;
    }
    if (isDateGreaterOrEquals(dateA, dateB, 'minute')) {
        return dateA;
    }
    return dateB;
}
export function getMinDateTime(dateA, dateB) {
    if (isNil(dateA) && isNil(dateB)) {
        return null;
    }
    if (isNil(dateA)) {
        return dateB;
    }
    if (isNil(dateB)) {
        return dateA;
    }
    if (isDateLessOrEquals(dateA, dateB, 'minute')) {
        return dateA;
    }
    return dateB;
}
export function getPastDaysFromNow(isoDate) {
    if (isNil(isoDate)) {
        return 0;
    }
    const rawDays = DateTime.fromISO(isoDate).diffNow('days').negate().days;
    return Math.floor(rawDays);
}
export function addDate(date, unit = 'month', count = 1) {
    if (isNil(date)) {
        return null;
    }
    return DateTime.fromISO(date)
        .plus({ [unit]: count })
        .toISODate();
}
export function subtractDate(date, unit = 'month', count = 1) {
    if (isNil(date)) {
        return null;
    }
    return DateTime.fromISO(date)
        .minus({ [unit]: count })
        .toISODate();
}
export const getTimeZoneString = (timeZone) => {
    var _a;
    if (timeZone === 'Alaska Time' || timeZone === 'Hawaii Time') {
        return `(${timeZone})`;
    }
    const regex = /\((.*?)\)/;
    const timeString = ((_a = timeZone === null || timeZone === void 0 ? void 0 : timeZone.match(regex)) === null || _a === void 0 ? void 0 : _a[0]) || `(${timeZone})` || '(utc)';
    return timeString;
};
export function formatRelativeTime(date, timeZone) {
    if (!date)
        return null;
    const timeString = getTimeZoneString(timeZone);
    const now = DateTime.local({ zone: TIME_MAP_ZONE[timeZone] || timeZone || 'utc' });
    const then = DateTime.fromISO(date, { zone: TIME_MAP_ZONE[timeZone] || timeZone || 'utc' });
    if (now.hasSame(then, 'day')) {
        const time = then.toLocaleString(DateTime.TIME_SIMPLE);
        return `Today, ${time} ${timeString}`;
    }
    // otherwise, format it as a date with time
    return `${then.toLocaleString(DateTime.DATETIME_MED)} ${timeString}`;
}
/** @deprecated Use `isDateBetween` instead. */
export function isBetween(date, [start, end]) {
    return isDateBetween(date, { start, end });
}
/** @deprecated Use `formatISO8601Date` instead. */
export function normalizeDateString(date) {
    if (isNil(date))
        return null;
    return DateTime.fromISO(date).toISO();
}
const isSameDate = (dateA, dateB) => {
    const steps = ['year', 'month', 'day'];
    return steps.every(step => dateA.hasSame(dateB, step));
};
const getValidDateTimeOrNull = (date) => {
    if (isNil(date))
        return null;
    const dateTime = DateTime.fromISO(date);
    return dateTime.isValid ? dateTime : null;
};
export const getMinTimeFromRange = (startDateTime, endDateTime) => {
    const start = getValidDateTimeOrNull(startDateTime);
    if (!start)
        return null;
    const end = getValidDateTimeOrNull(endDateTime);
    if (!end)
        return null;
    if (isSameDate(start, end))
        return start;
    return null;
};
/**
 * Transform a date into a formatted string based on a template.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 * @param template String with the template to [follow the format](https://moment.github.io/luxon/#/formatting?id=toformat).
 */
function formatDateLuxon(value, template) {
    const date = parseDate(value);
    const formatted = date.toFormat(template);
    return formatted;
}
/*
 * Transform a date using the `hh:mm a` format.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 */
export function formatTime(value) {
    return formatDateLuxon(value, DATE_TIME_SIMPLE_FORMAT);
}
export function formatDonationDate(date) {
    const donationDate = DateTime.fromISO(date);
    const hoursDiff = Math.floor(DateTime.now().diff(donationDate, 'hours').hours);
    if (hoursDiff === 0) {
        return 'Just created';
    }
    if (hoursDiff < 24) {
        return `${hoursDiff} ${hoursDiff === 1 ? 'hour ago' : 'hours ago'}`;
    }
    return donationDate.toFormat('MM/dd/yyyy');
}
