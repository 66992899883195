/**
 * Normalize the given `DefaultCampaignQuestionFragment` to be `Question`.
 * https://8base-dev.atlassian.net/browse/VSL-2063
 * @returns A object that satisfies `Question`.
 */
export function normalizeDefaultQuestion(data) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const id = (_a = data.question) === null || _a === void 0 ? void 0 : _a.id;
    const name = (_b = data.question) === null || _b === void 0 ? void 0 : _b.name;
    const type = (_c = data.question) === null || _c === void 0 ? void 0 : _c.type;
    const format = (_d = data.question) === null || _d === void 0 ? void 0 : _d.format;
    const category = (_e = data.question) === null || _e === void 0 ? void 0 : _e.category;
    const title = (_f = data.question) === null || _f === void 0 ? void 0 : _f.title;
    const options = (_h = (_g = data.question) === null || _g === void 0 ? void 0 : _g.options) !== null && _h !== void 0 ? _h : [];
    const isMandatory = (_k = (_j = data.question) === null || _j === void 0 ? void 0 : _j.isMandatory) !== null && _k !== void 0 ? _k : false;
    const question = {
        id,
        type,
        format,
        name,
        title,
        options: options,
        category,
        isMandatory,
    };
    return question;
}
