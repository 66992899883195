var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CommitmentType, } from '@vizsla/types';
import { INTEGRATION_AMPLY_TYPE, INTEGRATION_DOUBLE_THE_DONATION_TYPE } from '@vizsla/constants';
import { calculateBaseDonationAmount } from './calculateBaseDonationAmount';
const calculateDonationAmount = (baseAmount, company) => {
    const { ratio, maxMatch, minMatch } = company;
    let matchedAmount = baseAmount * ratio;
    const adjustedMaxMatch = maxMatch != null ? maxMatch : Number.MAX_SAFE_INTEGER;
    matchedAmount = Math.max(minMatch, Math.min(adjustedMaxMatch, matchedAmount));
    return matchedAmount;
};
const generateIntegrationMatchDonationBody = (donationParams) => {
    const { donorEmail, donorFirstName, donorLastName, companyName, companyDetails, integrationType, donationAmount, transactionId, campaignName, } = donationParams;
    let donationBody;
    switch (integrationType) {
        case INTEGRATION_AMPLY_TYPE:
            donationBody = {
                donor: {
                    email: donorEmail,
                    name: `${donorFirstName} ${donorLastName}`,
                },
                company: {
                    name: companyName,
                },
                amount: donationAmount,
                transaction_id: transactionId,
            };
            break;
        case INTEGRATION_DOUBLE_THE_DONATION_TYPE:
            donationBody = {
                doublethedonation_company_id: companyDetails.id,
                donation_identifier: transactionId,
                donor_first_name: donorFirstName,
                donor_last_name: donorLastName,
                donor_email: donorEmail,
                donation_amount: donationAmount,
                campaign: campaignName,
            };
            break;
        default:
            donationBody = undefined;
    }
    return donationBody;
};
export const generateDonationMatchCommitment = (donationMatchData, createDonorCommitment, createDonationMatch) => __awaiter(void 0, void 0, void 0, function* () {
    const { integrationType, integrationCompany, userEmail, userFirstName, userLastName, amount, fundraiser, team, experienceId, campaignId, donorId, companyDetails, transactionId, campaignName, frecuency, startDate, endDate, } = donationMatchData;
    const baseAmount = calculateBaseDonationAmount(amount, frecuency, startDate, endDate);
    const matchDonationBody = generateIntegrationMatchDonationBody({
        donorEmail: userEmail,
        donorFirstName: userFirstName,
        donorLastName: userLastName,
        companyName: integrationCompany,
        donationAmount: baseAmount,
        integrationType,
        transactionId,
        campaignName,
        companyDetails,
    });
    const integrationDonationAmount = calculateDonationAmount(baseAmount, companyDetails);
    let integrationResponse;
    let donorCommitmentResponse;
    try {
        integrationResponse = yield createDonationMatch(matchDonationBody);
    }
    catch (error) {
        console.error('Error while registrating donation match:', error);
        integrationResponse = error;
    }
    try {
        donorCommitmentResponse = yield createDonorCommitment({
            amount: integrationDonationAmount,
            company: integrationCompany,
            fundraiser: fundraiser ? { connect: { id: fundraiser.id } } : null,
            team: team ? { connect: { id: team.id } } : null,
            status: false,
            type: CommitmentType.DonorMatching,
            experience: { connect: { id: experienceId } },
            campaign: { connect: { id: campaignId } },
            donor: { connect: { id: donorId } },
            frequency: frecuency,
        });
    }
    catch (error) {
        console.error('Error while creating donor commitment:', error);
        donorCommitmentResponse = error;
    }
    return { integrationResponse, donorCommitmentResponse, integrationDonationAmount };
});
