export var QuestionTypes;
(function (QuestionTypes) {
    QuestionTypes["Text"] = "Text";
    QuestionTypes["Checkbox"] = "Checkbox";
    QuestionTypes["Radio"] = "Radio";
    QuestionTypes["Select"] = "Select";
    QuestionTypes["Switch"] = "Switch";
    QuestionTypes["Date"] = "Date";
    QuestionTypes["Autocomplete"] = "Autocomplete";
})(QuestionTypes || (QuestionTypes = {}));
export var QuestionCategories;
(function (QuestionCategories) {
    QuestionCategories["Personal"] = "Personal";
    QuestionCategories["Location"] = "Location";
    QuestionCategories["Company"] = "Company";
    QuestionCategories["Emergency"] = "Emergency";
    QuestionCategories["Other"] = "Other";
})(QuestionCategories || (QuestionCategories = {}));
export var QuestionFormats;
(function (QuestionFormats) {
    QuestionFormats["singleLine"] = "singleLine";
    QuestionFormats["multiLine"] = "multiLine";
    QuestionFormats["number"] = "number";
    QuestionFormats["money"] = "money";
    QuestionFormats["email"] = "email";
    QuestionFormats["phone"] = "phone";
    QuestionFormats["singleChoice"] = "singleChoice";
    QuestionFormats["multipleChoice"] = "multipleChoice";
    QuestionFormats["date"] = "date";
    QuestionFormats["dateTime"] = "dateTime";
    QuestionFormats["zip"] = "zip";
})(QuestionFormats || (QuestionFormats = {}));
