import LuxonUtils from '@date-io/luxon';
import { Info } from 'luxon';
export class CustomLuxonUtils extends LuxonUtils {
    constructor() {
        super(...arguments);
        this.getWeekdays = () => {
            // The weekdays Start from Monday
            const weekdays = Info.weekdaysFormat('short', { locale: this.locale });
            // rotate array to start from Sunday
            return weekdays.slice(-1).concat(weekdays.slice(0, -1));
        };
        // eslint-disable-next-line class-methods-use-this
        this.getWeekArray = date => {
            const { days } = date
                .endOf('month')
                .endOf('week')
                .diff(date.startOf('month').startOf('week'), 'days')
                .toObject();
            const weeks = [];
            new Array(Math.round(days))
                .fill(0)
                .map((_, i) => i)
                .map(day => date.startOf('month').startOf('week').minus({ days: 1 }).plus({ days: day }))
                .forEach((v, i) => {
                if (i === 0 || (i % 7 === 0 && i > 6)) {
                    weeks.push([v]);
                    return;
                }
                weeks[weeks.length - 1].push(v);
            });
            return weeks;
        };
    }
}
