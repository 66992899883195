import { DATE_SQL_FORMAT, DATE_TABLE_FORMAT, DATE_USA_FORMAT, REGEX_SQL_FORMAT, REGEX_ISO8601_FORMAT, } from '@vizsla/constants';
import { currentDateTime, parseDate } from './parseDates';
/**
 * Transform a date into a formatted string based on a template.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 * @param template String with the template to [follow the format](https://moment.github.io/luxon/#/formatting?id=toformat).
 */
function formatDate(value, template) {
    const date = parseDate(value);
    const formatted = date.toFormat(template);
    if (date.isValid === false && date.invalidReason) {
        return date.invalidReason;
    }
    return formatted;
}
export function formatMilliseconds(value = currentDateTime()) {
    const date = parseDate(value);
    return date.toMillis();
}
export function formatShortDate(value, options) {
    var _a;
    const date = parseDate(value);
    const now = currentDateTime();
    const omitYear = (_a = options === null || options === void 0 ? void 0 : options.omitYear) !== null && _a !== void 0 ? _a : true;
    if (omitYear && date.year === now.year) {
        return formatDate(value, 'MMMM dd');
    }
    return formatDate(value, 'MMMM dd, yyyy');
}
export function formatSQLDate(value = currentDateTime()) {
    if (typeof value === 'string' && REGEX_SQL_FORMAT.test(value)) {
        return value;
    }
    return formatDate(value, DATE_SQL_FORMAT);
}
export function formatISO8601Date(value = currentDateTime()) {
    if (typeof value === 'string' && REGEX_ISO8601_FORMAT.test(value)) {
        return value;
    }
    const date = parseDate(value);
    if (date.isValid && date.invalidReason) {
        throw new Error(date.invalidReason);
    }
    return date.toISO({ format: 'extended' });
}
export function formatUSADate(value = currentDateTime()) {
    return formatDate(value, DATE_USA_FORMAT);
}
export function formatTableDate(value = currentDateTime()) {
    return formatDate(value, DATE_TABLE_FORMAT);
}
export function formatLongDate(value = currentDateTime()) {
    return formatDate(value, `MMMM dd, yyyy 'at' TTT`);
}
const RELATIVE_TIME_FORMAT_UNITS = [
    'year',
    'month',
    'week',
    'day',
    'hour',
    'minute',
    'second',
];
/**
 * Format a date into readable text in time-ago.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 * @param from Date on available formats (ISO, UNIX, SQL or JS Date).
 * @example `2 days ago`
 * @returns Given `value` within time-ago date format.
 */
export function formatRelativeDate(value, from = currentDateTime()) {
    var _a;
    const selectedDate = parseDate(value);
    const relativeDate = parseDate(from);
    const diff = selectedDate.diff(relativeDate).shiftTo(...RELATIVE_TIME_FORMAT_UNITS);
    const unit = (_a = RELATIVE_TIME_FORMAT_UNITS.find(unit => Math.abs(diff.get(unit)) > 0)) !== null && _a !== void 0 ? _a : 'second';
    const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
    const amount = Math.round(diff.as(unit));
    return formatter.format(amount, unit);
}
