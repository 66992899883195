/**
 * Structure of field `type` the **People** report.
 * @ticket https://8base-dev.atlassian.net/browse/VSL-2109
 */
export var PeopleReportType;
(function (PeopleReportType) {
    PeopleReportType["Attendee"] = "Attendee";
    PeopleReportType["Fundraiser"] = "Fundraiser";
    PeopleReportType["Donor"] = "Donor";
})(PeopleReportType || (PeopleReportType = {}));
